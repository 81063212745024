/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";

export default function Heading(props) {
  const { children, fontSize = [24, 36] } = props;
  return (
    <h1
      sx={{
        m: 0,
        fontSize: fontSize,
        fontWeight: 300,
        fontFamily: "heading"
      }}
      {...props}
    >
      {children}
    </h1>
  );
}
