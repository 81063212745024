/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useRef, useEffect } from "react";
import { jsx, Button, Input, Label } from "theme-ui";

function Login({ callback }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const inputRef = useRef();

  const handleError = error => {
    setLoading(false);
    setError(error);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    callback(email.toLowerCase(), password, handleError);
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <form
      sx={{
        width: "90%",
        maxWidth: 500,
        p: [10, 50],
        mx: "auto"
      }}
      onSubmit={handleSubmit}
    >
      <h1
        sx={{
          textAlign: "left",
          mt: 0,
          fontSize: 55,
          fontWeight: 300,
          fontFamily: "heading"
        }}
      >
        Login
      </h1>
      {error && (
        <div
          sx={{
            backgroundColor: "error",
            color: "white",
            padding: 20,
            mb: 30,
            fontSize: 14
          }}
        >
          {error}
        </div>
      )}
      <Label htmlFor="email">Email</Label>
      <Input
        name="email"
        placeholder="your@email.com"
        required
        ref={inputRef}
        onChange={e => setEmail(e.target.value)}
      />
      <Label htmlFor="password" sx={{ mt: 3 }}>
        Password
      </Label>
      <Input
        name="password"
        type="password"
        placeholder="Enter your password"
        required
        onChange={e => setPassword(e.target.value)}
      />
      <div
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: "10px",
          fontSize: "90%"
        }}
      >
        <a
          sx={{ variant: "components.link", color: "red" }}
          target="_blank"
          rel="noreferrer"
          href="https://planque-backend.onrender.com/accounts/password_reset/"
        >
          Forgot password?
        </a>
      </div>
      <Button sx={{ width: "100%", my: 36 }} type="submit">
        {loading ? "Logging in..." : "Login"}
      </Button>
      <div sx={{ fontSize: "90%", textAlign: "center", color: "black" }}>
        Don't have an account?{" "}
        <a sx={{ variant: "components.link", color: "red" }} href="/register">
          Register
        </a>
      </div>
    </form>
  );
}

export default Login;
