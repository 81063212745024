/** @jsxRuntime classic */
/** @jsx jsx */
import axios from "axios";
import { useState, useEffect } from "react";
import { jsx, Button, Heading, Input, Label } from "theme-ui";

import { baseUrl } from "../utils/constants";
import { useThrottledCallback } from "use-debounce";

export default function AddToCellar({ uuid, closeModal, fetchCellar, token }) {
  const [adding, setAdding] = useState(false);
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState(false);

  const [payload, setPayload] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [existingBottleReference, setExistingBottleReference] = useState(null);

  const resetFields = () => {
    setExistingBottleReference(null);
    setSearchResults([]);
    setInputs({
      domain: "",
      search: "",
      country: "",
      appellation: "",
      cuvee: "",
      region: "",
      colour: "",
      vintage: "",
      size: "",
      quantity: "",
    });
  };

  useEffect(() => {
    resetFields();
  }, []);

  const addToCellar = () => {
    setAdding(true);
    setError(false);

    const variant = {
      quantity: parseInt(inputs.quantity),
      colour: inputs.colour,
      vintage: parseInt(inputs.vintage),
      size: parseInt(inputs.size),
      name: "",
    };

    let _payload = {};

    if (existingBottleReference) {
      _payload = {
        user_uuid: uuid,
        existingBottles: [
          {
            uuid: existingBottleReference,
            ...variant,
          },
        ],
      };
    } else {
      _payload = {
        user_uuid: uuid,
        bottlesToCreate: [
          {
            region: inputs.region,
            designation: inputs.appellation,
            producer: inputs.domain,
            cuvee: inputs.cuvee,
            country: inputs.country,
            ...variant,
          },
        ],
      };
    }

    setPayload(_payload);

    axios({
      method: "POST",
      url: baseUrl + `/api/admin/user/${uuid}/deposits/`,
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        ..._payload,
      },
    })
      .then((response) => {
        console.log("Add to cellar", response);
        setAdding(false);
        closeModal();
        fetchCellar();
        resetFields();
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setAdding(false);
      });
  };

  const search = () => {
    setSearchLoading(true);
    if (!inputs.search) return;

    const url =
      baseUrl +
      `/api/bottles/metadata/?search_param=bottle&search_term=${encodeURIComponent(
        inputs.search.toLowerCase()
      )}`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((response) => {
        console.log("Search results:", response);
        setSearchResults(response.data.results);
        setSearchLoading(false);
      })
      .catch((error) => {
        setSearchLoading(false);
        console.error(error);
      });
  };

  const debouncedSearch = useThrottledCallback(search, 2000);

  const selectResult = (result) => {
    setSearchResults([]);
    setInputs((state) => ({
      ...state,
      search: "",
      country: result.country,
      appellation: result.appellation,
      cuvee: result.cuvee,
      domain: result.domain,
      region: result.region,
    }));
    setExistingBottleReference(result.uuid);
  };

  return (
    <div sx={{ width: 1000 }}>
      <div sx={{ padding: 30 }}>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            sx={{ position: "relative" }}
          >
            <Label htmlFor="search">Search</Label>
            <div sx={{ position: "relative", zIndex: 2 }}>
              <Input
                name="search"
                placeholder="Search for exisiting bottle..."
                onChange={({ target }) => {
                  setInputs((state) => ({
                    ...state,
                    search: target.value,
                  }));
                  if (target.value !== "") {
                    debouncedSearch();
                  } else {
                    setSearchResults([]);
                  }
                }}
                value={inputs.search}
              />
              {searchLoading && (
                <div
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: 48,
                    height: 48,
                  }}
                >
                  <div className="loader--s" />
                </div>
              )}
            </div>
            {searchResults.length > 0 && (
              <div
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  width: "100%",
                  maxHeight: "600px",
                  overflow: "scroll",
                  boxShadow: "0 0 20px 0 rgba(0,0,0,0.2)",
                  padding: 20,
                  zIndex: 1,
                  background: "white",
                }}
              >
                {searchResults.map((result, i) => {
                  return (
                    <div
                      key={`result-${i}`}
                      sx={{
                        p: 20,
                        lineHeight: 1.4,
                        borderBottom: "1px solid rgb(0, 0, 0, 0.1)",
                        "&:last-child": {
                          borderBottom: "none",
                        },
                        "&:hover": {
                          cursor: "pointer",
                          backgroundColor: "beige",
                        },
                      }}
                      onClick={() => {
                        selectResult(result);
                      }}
                    >
                      <div sx={{ display: "flex" }}>
                        <div sx={{ width: 140 }}>
                          <Label sx={{ opacity: 0.2 }}>Country</Label>
                          {result.country}
                        </div>
                        <div
                          sx={{
                            display: "grid",
                            width: "calc(100% - 140px)",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr",
                            gap: 10,
                          }}
                        >
                          <div>
                            <Label sx={{ opacity: 0.2 }}>Region</Label>
                            {result.region}
                          </div>
                          <div>
                            <Label sx={{ opacity: 0.2 }}>Designation</Label>
                            {result.appellation}
                          </div>
                          <div>
                            <Label sx={{ opacity: 0.2 }}>Producer</Label>
                            {result.domain}
                          </div>
                          <div>
                            <Label sx={{ opacity: 0.2 }}>Cuveé</Label>
                            {result.cuvee ? result.cuvee : "–"}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </form>
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          addToCellar();
        }}
      >
        <div sx={{ padding: 30 }}>
          <div sx={{ display: "flex" }}>
            <Heading sx={{ mb: 20 }}>Bottle</Heading>

            {!!existingBottleReference && (
              <div sx={{ ml: "auto" }}>
                <Button
                  variant="reset"
                  sx={{ color: "black", textDecoration: "underline" }}
                  onClick={() => {
                    setExistingBottleReference(null);
                  }}
                >
                  edit
                </Button>
              </div>
            )}
          </div>

          <div
            sx={{
              display: "grid",
              gap: 30,
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <div>
              <Label htmlFor="region">Region</Label>
              <Input
                name="region"
                disabled={!!existingBottleReference}
                onChange={({ target }) =>
                  setInputs((state) => ({ ...state, region: target.value }))
                }
                value={inputs.region}
                placeholder="Region..."
                required
              />
            </div>
            <div>
              <Label htmlFor="appellation">Designation</Label>
              <Input
                name="appellation"
                disabled={!!existingBottleReference}
                placeholder="Designation..."
                onChange={({ target }) =>
                  setInputs((state) => ({
                    ...state,
                    appellation: target.value,
                  }))
                }
                value={inputs.appellation}
              />
            </div>
            <div>
              <Label htmlFor="domain">Producer</Label>
              <Input
                name="domain"
                placeholder="Producer..."
                disabled={!!existingBottleReference}
                required
                onChange={({ target }) =>
                  setInputs((state) => ({
                    ...state,
                    domain: target.value,
                  }))
                }
                value={inputs.domain}
              />
            </div>
            <div>
              <Label htmlFor="cuvee">Cuvee</Label>
              <Input
                name="cuvee"
                placeholder="Cuvee..."
                disabled={!!existingBottleReference}
                onChange={({ target }) =>
                  setInputs((state) => ({
                    ...state,
                    cuvee: target.value,
                  }))
                }
                value={inputs.cuvee}
              />
            </div>
            <div>
              <Label htmlFor="country">Country</Label>
              <Input
                name="country"
                maxLength="2"
                placeholder="Enter country code"
                disabled={!!existingBottleReference}
                required
                onChange={({ target }) =>
                  setInputs((state) => ({
                    ...state,
                    country: target.value,
                  }))
                }
                value={inputs.country}
              />
            </div>
          </div>
        </div>
        <div sx={{ padding: 30 }}>
          <Heading sx={{ mb: 20 }}>Variant</Heading>
          <div
            sx={{
              display: "grid",
              gap: 30,
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
            }}
          >
            <div>
              <Label htmlFor="colour">Colour</Label>
              <Input
                name="colour"
                onChange={({ target }) =>
                  setInputs((state) => ({ ...state, colour: target.value }))
                }
                value={inputs.colour}
                placeholder="Red/White"
                required
              />
            </div>
            <div>
              <Label htmlFor="vintage">Vintage</Label>
              <Input
                name="vintage"
                placeholder="Enter year"
                type="number"
                required
                onChange={({ target }) =>
                  setInputs((state) => ({
                    ...state,
                    vintage: target.value,
                  }))
                }
                value={inputs.vintage}
              />
            </div>
            <div>
              <Label htmlFor="size">Size</Label>
              <Input
                name="size"
                type="number"
                placeholder="Enter size in ml"
                required
                onChange={({ target }) =>
                  setInputs((state) => ({
                    ...state,
                    size: target.value,
                  }))
                }
                value={inputs.size}
              />
            </div>
            <div>
              <Label htmlFor="size">Quantity</Label>
              <Input
                name="quantity"
                min="1"
                placeholder="Enter quantity"
                type="number"
                required
                onChange={({ target }) =>
                  setInputs((state) => ({
                    ...state,
                    quantity: target.value,
                  }))
                }
                value={inputs.quantity}
              />
            </div>
          </div>
          <div>
            <Button
              sx={{
                variant: "primary",
                px: 40,
                mt: 30,
                width: "100%",
                borderRadius: 100,
              }}
              type="submit"
            >
              {adding ? "Adding..." : "Add to cellar"}
            </Button>
          </div>
          {error && (
            <div
              sx={{
                mt: 20,
                p: 20,
                backgroundColor: "red",
                color: "white",
                textAlign: "center",
                borderRadius: 23,
              }}
            >
              Oops! {error.message}
            </div>
          )}
        </div>
      </form>
      <div sx={{ display: "none" }}>
        <pre sx={{ background: "green", color: "white" }}>
          <code>
            {JSON.stringify(
              {
                payload,
              },
              null,
              2
            )}
          </code>
        </pre>
        <pre>
          <code>
            {JSON.stringify(
              {
                inputs,
                existingBottleReference,
                searchResults,
              },
              null,
              2
            )}
          </code>
        </pre>
      </div>
    </div>
  );
}
