/** @jsxRuntime classic */
/** @jsx jsx */
import axios from "axios";
import React, { useState, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { jsx, Button, Input, Label, Heading } from "theme-ui";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTable, useSortBy } from "react-table";
import "react-tabs/style/react-tabs.css";
import SortUp from "../icons/SortUp";
import SortDown from "../icons/SortDown";
import RequestsTable from "../components/RequestsTable";
import Modal from "../components/Modal";

import { retrieveAuth } from "../App";
import { baseUrl } from "../utils/constants";
import Header from "./Header";

export default function Dashboard() {
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState(retrieveAuth());
  const [users, setUsers] = useState([]);
  const [requests, setRequests] = useState([]);
  const [historicalRequests, setHistoricalRequests] = useState([]);

  const [usersLoaded, setUsersLoaded] = useState(false);
  const [usersLoadedBackground, setUsersLoadedBackground] = useState(false);

  const [requestsLoaded, setRequestsLoaded] = useState(false);
  const [requestsLoadedBackground, setRequestsLoadedBackground] =
    useState(false);

  const [historicalRequestsLoaded, setHistoricalRequestsLoaded] =
    useState(false);
  const [
    historicalRequestsLoadedBackground,
    setHistoricalRequestsLoadedBackground,
  ] = useState(false);
  const [historyPage, setHistoryPage] = useState(1);

  const fetchRequests = () => {
    axios({
      method: "get",
      url: baseUrl + "/api/admin/requests/?selection=active",
      headers: {
        Authorization: "Token " + auth.token,
      },
    }).then((response) => {
      setRequests(response.data.results);
    });
  };

  const fetchUsers = () => {
    axios({
      method: "get",
      url: baseUrl + "/api/admin/users/",
      headers: {
        Authorization: "Token " + auth.token,
      },
    }).then((response) => {
      setUsers(filterUsers(response.data.results));
    });
  };

  const filterUsers = (users) => {
    const filterEmails = [
      "oleg@tsapl.in",
      "seanmpreston@gmail.com",
      "members@planque.co.uk",
      "j.alphandery@gmail.com",
      "jonathan@planque.co.uk",
    ];

    const filteredUsers = users.filter((user) => {
      return !filterEmails.includes(user.email);
    });

    return filteredUsers;
  };

  const loadMore = () => {
    const nextPage = historyPage + 1;
    setHistoryPage(nextPage);
    setHistoricalRequestsLoadedBackground(false);
    loadHistoricalRequests(nextPage, auth.token);
  };

  const loadHistoricalRequests = (page = 1, token) => {
    axios({
      method: "get",
      url:
        baseUrl +
        `/api/admin/requests/?selection=historical&page=${page}&limit=20`,
      headers: {
        Authorization: "Token " + token,
      },
    }).then((response) => {
      const results = [...historicalRequests, ...response.data.results];
      setHistoricalRequests(results);
      setHistoricalRequestsLoaded(true);
      setHistoricalRequestsLoadedBackground(true);
    });
  };

  useEffect(() => {
    const auth = retrieveAuth();
    setUser(auth.user);
    setAuth(auth.token);
    console.log("Admin", user, auth);
    const { token } = auth.token;
    const cachedUsers = localStorage.getItem("@planque-admin-users");
    if (cachedUsers) {
      setUsers(filterUsers(JSON.parse(cachedUsers).users));
      setUsersLoaded(true);
    }

    axios({
      method: "get",
      url: baseUrl + "/api/admin/requests/?selection=active",
      headers: {
        Authorization: "Token " + token,
      },
    }).then((response) => {
      setRequests(response.data.results);
      setRequestsLoaded(true);
      setRequestsLoadedBackground(true);
      localStorage.setItem(
        "@planque-admin-requests",
        JSON.stringify({
          requests: response.data.results,
          lastUpdated: new Date(),
        })
      );
    });

    loadHistoricalRequests(1, token);

    axios({
      method: "get",
      url: baseUrl + "/api/admin/users/",
      headers: {
        Authorization: "Token " + token,
      },
    }).then((response) => {
      setUsers(filterUsers(response.data.results));
      setUsersLoaded(true);
      setUsersLoadedBackground(true);
      localStorage.setItem(
        "@planque-admin-users",
        JSON.stringify({
          users: response.data.results,
          lastUpdated: new Date(),
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      sx={{
        minHeight: "100vh",
      }}
    >
      <Header user={user} isAdmin />

      <div
        sx={{
          px: [10, 50],
          pb: [10, 50],
        }}
      >
        <div sx={{ mt: 30 }}>
          <Tabs defaultIndex={0}>
            <div sx={{ position: "relative" }}>
              <TabList>
                <Tab>
                  <div sx={{ display: "flex" }}>
                    Users {users.length > 0 && `(${users.length})`}{" "}
                    {!usersLoadedBackground && (
                      <div className="loader--xxs"></div>
                    )}
                  </div>
                </Tab>
                <Tab>
                  <div sx={{ display: "flex" }}>
                    Requests {requests.length > 0 && `(${requests.length})`}
                    {(!requestsLoadedBackground ||
                      !historicalRequestsLoadedBackground) && (
                      <div className="loader--xxs"></div>
                    )}
                  </div>
                </Tab>
              </TabList>
            </div>

            <TabPanel>
              <div sx={{ mt: 30 }}>
                {!usersLoaded ? (
                  <div
                    sx={{
                      variant: "components.flexCenter",
                      width: "100%",
                      height: 500,
                    }}
                  >
                    <span>
                      <div className="loader"></div>
                    </span>
                  </div>
                ) : (
                  <Users
                    users={users}
                    token={auth.token}
                    fetchUsers={fetchUsers}
                  />
                )}
              </div>
            </TabPanel>
            <TabPanel>
              <div sx={{ mt: 30, position: "relative" }}>
                <Heading sx={{ mb: 2 }}>Pending</Heading>
                {!requestsLoaded ? (
                  <div
                    sx={{
                      variant: "components.flexCenter",
                      width: "100%",
                      height: 500,
                    }}
                  >
                    <span>
                      <div className="loader"></div>
                    </span>
                  </div>
                ) : (
                  <RequestsTable
                    data={requests}
                    isAdmin={true}
                    fetchRequests={fetchRequests}
                    token={auth.token}
                  />
                )}
              </div>

              <div sx={{ mt: 30, position: "relative" }}>
                <Heading sx={{ mb: 2 }}>History</Heading>
                {!historicalRequestsLoaded ? (
                  <div
                    sx={{
                      variant: "components.flexCenter",
                      width: "100%",
                      height: 500,
                    }}
                  >
                    <span>
                      <div className="loader"></div>
                    </span>
                  </div>
                ) : (
                  <div>
                    <RequestsTable
                      data={historicalRequests}
                      isAdmin={true}
                      fetchRequests={fetchRequests}
                      token={auth.token}
                    />
                    <div sx={{ variant: "components.flexCenter" }}>
                      <Button
                        sx={{
                          variant: "buttons.ghost",
                          px: 40,
                          mt: 30,
                          width: "100%",
                          maxWidth: 300,
                          mx: "auto",
                          borderRadius: 100,
                        }}
                        onClick={() => {
                          loadMore();
                        }}
                      >
                        {historicalRequestsLoadedBackground
                          ? "LOAD MORE"
                          : "LOADING..."}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

const Users = ({ users, uuid, token, fetchUsers }) => {
  const [editingRow, setEditingRow] = useState(false);
  const [editFlow, setEditFlow] = useState(false);

  const openEditModal = (props) => {
    setEditingRow(props.original);
    setEditFlow(true);
  };

  const closeModal = () => {
    setEditFlow(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Membership ID",
        accessor: "external_id",
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {props.cell.value ? props.cell.value : "-"}
              <div
                className="hideChildren--child"
                sx={{
                  position: "absolute",
                  top: "-5px",
                  bottom: "-5px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderRadius: 20,
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  px: 10,
                  fontWeight: "400",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  openEditModal(props.row);
                }}
              >
                edit
              </div>
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        capitalize: true,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Bottle Count",
        accessor: "bottle_count",
        Cell: (props) => {
          return <div>{props.cell.value ? props.cell.value : 0}</div>;
        },
      },
      {
        Header: "Max Bottles",
        accessor: "cellar_allowance",
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {props.cell.value ? props.cell.value : "-"}
              <div
                className="hideChildren--child"
                sx={{
                  position: "absolute",
                  top: "-5px",
                  bottom: "-5px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderRadius: 20,
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  px: 10,
                  fontWeight: "400",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  openEditModal(props.row);
                }}
              >
                edit
              </div>
            </div>
          );
        },
      },
      {
        Header: "Corkage",
        accessor: "corkage",
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {props.cell.value ? props.cell.value : "-"}
              <div
                className="hideChildren--child"
                sx={{
                  position: "absolute",
                  top: "-5px",
                  bottom: "-5px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderRadius: 20,
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  px: 10,
                  fontWeight: "400",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  openEditModal(props.row);
                }}
              >
                edit
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data: users,
        columns,
        initialState: {
          sortBy: [
            {
              id: "external_id",
              desc: false,
            },
          ],
        },
      },
      useSortBy
    );

  return (
    <div>
      <Modal
        isOpen={editFlow}
        closeModal={closeModal}
        heading={`${editingRow.name} (${editingRow.email})`}
      >
        {editingRow && (
          <EditUser
            editingRow={editingRow}
            token={token}
            closeModal={closeModal}
            fetchUsers={fetchUsers}
          />
        )}
      </Modal>
      <table
        {...getTableProps()}
        id="planque"
        style={{
          width: "100%",
          fontSize: 16,
          borderCollapse: "collapse",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`group-${i}`}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  sx={{
                    width: column.columnWidth
                      ? `${column.columnWidth}px`
                      : "auto",
                    textTransform: column.capitalize ? "capitalize" : "initial",
                  }}
                >
                  <div
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="table-header"
                  >
                    {column.render("Header")}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <SortDown />
                      ) : (
                        <SortUp />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} sx={{ textAlign: "center" }}>
          {rows.map((row, i) => {
            prepareRow(row);
            return <MemoizedRow row={row} key={`row-${i}`} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

const MemoizedRow = memo((props) => {
  const history = useHistory();
  const { row, isSelected } = props;
  return (
    <tr
      {...row.getRowProps()}
      onClick={(e) => {
        console.log(e);
        if (!e.target.classList.contains("hideChildren--child")) {
          history.push(`/admin/cellar/${row.original.uuid}/`);
        }
      }}
      sx={{
        height: 32,
        cursor: "pointer",
        backgroundColor: isSelected ? "lightGreen" : "transparent",
        "&:hover": {
          backgroundColor: isSelected ? "lightGreen" : "beige",
        },
      }}
    >
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            sx={{
              textTransform: cell.column.capitalize ? "capitalize" : "initial",
            }}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
});

const EditUser = (props) => {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    const { cellar_allowance, corkage_allowance, corkage_used, external_id } =
      props.editingRow;
    console.log("Edit User", props.editingRow);

    setInputs({
      cellar_allowance: cellar_allowance,
      corkage_allowance: corkage_allowance,
      corkage_used: corkage_used,
      external_id: external_id,
    });
  }, [props]);

  const save = () => {
    const { uuid } = props.editingRow;
    const { token, closeModal, fetchUsers } = props;
    setLoading(true);

    axios({
      method: "POST",
      url: `${baseUrl}/api/admin/user/${uuid}/`,
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        cellar_allowance: inputs.cellar_allowance,
        corkage_allowance: inputs.corkage_allowance,
        external_id: inputs.external_id,
      },
    })
      .then((response) => {
        setLoading(false);
        closeModal();
        fetchUsers();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const deleteUser = () => {
    const { uuid } = props.editingRow;
    const { token, closeModal, fetchUsers } = props;

    if (window.confirm("Are you sure you want to delete this user?") === true) {
      setLoadingDelete(true);
      axios({
        method: "DELETE",
        url: `${baseUrl}/api/admin/user/${uuid}/`,
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((response) => {
          setLoadingDelete(false);
          closeModal();
          fetchUsers();
        })
        .catch((error) => {
          setLoadingDelete(false);
          console.error(error);
        });
    }
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
        sx={{ position: "relative" }}
      >
        <div sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 20 }}>
          <div>
            <Label htmlFor="cellar_allowance">Max Bottles</Label>
            <Input
              name="cellar_allowance"
              type="number"
              required
              placeholder="72"
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  cellar_allowance: target.value,
                }))
              }
              value={inputs.cellar_allowance}
            />
          </div>

          <div>
            <Label htmlFor="corkage_allowance">Corkage Allowance</Label>
            <Input
              name="corkage_allowance"
              placeholder="Enter corkage_allowance"
              type="number"
              required
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  corkage_allowance: target.value,
                }))
              }
              value={inputs.corkage_allowance}
            />
          </div>

          <div sx={{ display: "none" }}>
            <Label htmlFor="corkage_used">Corkage Used</Label>
            <Input
              name="corkage_used"
              placeholder="Enter corkage_used"
              type="number"
              required
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  corkage_used: target.value,
                }))
              }
              value={inputs.corkage_used}
            />
          </div>

          <div>
            <Label htmlFor="external_id">Membership ID</Label>
            <Input
              name="external_id"
              placeholder="Enter membership ID"
              type="text"
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  external_id: target.value,
                }))
              }
              value={inputs.external_id}
            />
          </div>
        </div>
        <div sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              variant: "primary",
              px: 40,
              mt: 30,
              flex: 1,
              mr: 20,
              borderRadius: 100,
            }}
            type="submit"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
          <Button
            sx={{
              variant: "buttons.ghost",
              px: 40,
              mt: 30,
              width: 200,
              borderRadius: 100,
              "&:hover": {
                background: "#eb2f06",
                color: "white",
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              deleteUser();
            }}
          >
            {loadingDelete ? "Deleting..." : "Delete user"}
          </Button>
        </div>
      </form>
    </div>
  );
};
