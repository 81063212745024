import axios from "axios";
import { baseUrl } from "./constants";

const tokenAuth = {
  isAuthenticated: false,
  tokenProviderBase: baseUrl,
  signup(username, email, password, cb, setError) {
    axios({
      method: "post",
      url: tokenAuth.tokenProviderBase + "/api/signup/",
      data: {
        name: username,
        email: email,
        password: password
      }
    })
      .then(response => {
        tokenAuth.isAuthenticated = true;
        cb(response.data);
      })
      .catch(error => {
        const statusCode = error.response && error.response.status;
        setError(
          `Oops! Looks like there was an error creating your account ${
            statusCode ? `(${statusCode})` : ""
          }`
        );
      });
  },
  signin(email, password, cb, setError) {
    axios({
      method: "post",
      url: tokenAuth.tokenProviderBase + "/api/signin/",
      data: {
        email: email,
        password: password
      }
    })
      .then(response => {
        tokenAuth.isAuthenticated = true;
        cb(response.data);
      })
      .catch(error => {
        const statusCode = error.response && error.response.status;
        setError(
          `Oops! Looks like your email or password was incorrect ${
            statusCode ? `(${statusCode})` : ""
          }`
        );
      });
  },
  signout(authedUser, cb) {
    axios({
      headers: {
        Authorization: "Token " + authedUser.token.token
      },
      method: "post",
      url: tokenAuth.tokenProviderBase + "/api/logout/"
    }).then(response => {
      tokenAuth.isAuthenticated = false;
      cb();
    });
  }
};

export default tokenAuth;
