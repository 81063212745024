import React from "react";

function Close({ fill = "black" }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 17L17 1M1 1L17 17" stroke={fill} strokeWidth="2" />
    </svg>
  );
}

export default Close;
