/** @jsxRuntime classic */
/** @jsx jsx */
import { useContext, createContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { jsx } from "theme-ui";
import ReactGA from "react-ga";

import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import AdminCellar from "./components/AdminCellar";
import Admin from "./components/Admin";
import logo from "./logo-white.svg";
import tokenAuth from "./utils/TokenAuth";

export default function AuthExample() {
  useEffect(() => {
    ReactGA.initialize("G-D8S7EQZPCH");
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <ProvideAuth>
      <Router>
        <div>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/register">
              <RegisterPage />
            </Route>
            <PrivateRoute path={`/admin/cellar/:uuid/`}>
              <AdminCellar />
            </PrivateRoute>
            <PrivateRoute path="/admin">
              <Admin />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function storeAuth(data) {
  const asJson = JSON.stringify(data);
  window.localStorage.setItem("auth_data", asJson);
}

export function retrieveAuth() {
  const asString = window.localStorage.getItem("auth_data");
  if (asString) {
    return JSON.parse(asString);
  }
}

function removeAuth() {
  window.localStorage.removeItem("auth_data");
}

function useProvideAuth() {
  const [user, setUser] = useState(null);

  if (!user) {
    const authData = retrieveAuth();
    if (authData) {
      const now = new Date();
      const expiresAt = new Date(authData.token.expires);
      const isExpired = now > expiresAt;
      if (isExpired) {
        removeAuth();
      } else {
        setUser(authData);
      }
    }
  }

  const signup = (username, email, password, cb, setError) => {
    return tokenAuth.signup(
      username,
      email,
      password,
      (data) => {
        setUser(data);
        storeAuth(data);
        cb();
      },
      setError
    );
  };

  const signin = (email, password, cb, setError) => {
    return tokenAuth.signin(
      email,
      password,
      (data) => {
        setUser(data);
        storeAuth(data);
        cb();
      },
      setError
    );
  };

  const signout = (authedUser, cb) => {
    return tokenAuth.signout(authedUser, () => {
      removeAuth(null);
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout,
    signup,
  };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function LoginPage() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };
  let login = (email, password, setError) => {
    auth.signin(
      email,
      password,
      () => {
        history.replace(from);
      },
      setError
    );
  };

  return (
    <Wrapper>
      <Login callback={login} />
    </Wrapper>
  );
}

function Wrapper({ children }) {
  return (
    <div
      sx={{
        display: "flex",
        height: "100vh",
        backgroundColor: "red",
      }}
    >
      <a
        href="/"
        sx={{
          width: "50%",
          variant: "components.flexCenter",
          display: ["none", "flex"],
        }}
      >
        <img src={logo} alt="Planque" height={50} />
      </a>
      <div
        sx={{
          width: ["100%", "50%"],
          variant: "components.flexCenter",
          backgroundColor: "pastel",
        }}
      >
        {children}
      </div>
    </div>
  );
}

function RegisterPage() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };
  let signup = (username, email, password, setError) => {
    auth.signup(
      username,
      email,
      password,
      () => {
        history.replace(from);
      },
      setError
    );
  };

  return (
    <Wrapper>
      <Register callback={signup} />
    </Wrapper>
  );
}
