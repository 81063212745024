import React from "react";

function Back({ fill = "black" }) {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 8H22M2 8L9 1M2 8L9 15" stroke={fill} strokeWidth="2" />
    </svg>
  );
}

export default Back;
