/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import { useHistory } from "react-router-dom";
import { useAuth } from "../App";

export default function AuthButton() {
  let history = useHistory();
  let auth = useAuth();

  return auth.user ? (
    <Button
      onClick={() => {
        auth.signout(auth.user, () => history.push("/"));
      }}
      variant="reset"
      sx={{
        color: "currentColor",
        fontSize: 12,
        textTransform: "uppercase",
        fontWeight: "bold",
        letterSpacing: 1,
        borderBottom: "1px solid transparent",
        "&:hover": {
          borderBottom: "1px solid currentColor",
        },
      }}
    >
      Sign out
    </Button>
  ) : null;
}
