/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useRef, useEffect } from "react";
import { jsx, Button, Input } from "theme-ui";
import { Label } from "theme-ui";
// import { tokenAuth } from "./utils/TokenAuth";

function Login({ callback }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleError = error => {
    setLoading(false);
    setError(error);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    const name = `${firstName} ${lastName}`;
    callback(name, email.toLowerCase(), password, handleError);
  };

  return (
    <form
      sx={{
        width: "90%",
        maxWidth: 500,
        p: [10, 50],
        mx: "auto"
      }}
      onSubmit={handleSubmit}
    >
      <h1
        sx={{
          textAlign: "left",
          mt: 0,
          fontSize: 55,
          fontWeight: 300,
          fontFamily: "heading"
        }}
      >
        Register
      </h1>
      {error && (
        <div
          sx={{
            backgroundColor: "error",
            color: "white",
            padding: 20,
            mb: 30,
            fontSize: 14
          }}
        >
          {error}
        </div>
      )}
      <div
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 30 }}
      >
        <div>
          <Label htmlFor="firstName">First Name</Label>
          <Input
            name="firstName"
            ref={inputRef}
            placeholder="First Name"
            required
            onChange={e => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="lastName">Last Name</Label>
          <Input
            name="lastName"
            ref={inputRef}
            placeholder="Last Name"
            required
            onChange={e => setLastName(e.target.value)}
          />
        </div>
      </div>
      <Label htmlFor="email" sx={{ mt: 3 }}>
        Email
      </Label>
      <Input
        name="email"
        placeholder="your@email.com"
        required
        onChange={e => setEmail(e.target.value)}
      />
      <Label htmlFor="password" sx={{ mt: 3 }}>
        Password
      </Label>
      <Input
        name="password"
        type="password"
        placeholder="Enter your password"
        required
        onChange={e => setPassword(e.target.value)}
      />
      <Button sx={{ width: "100%", my: 36 }} type="submit">
        {loading ? "Creating account..." : "Create account"}
      </Button>
      <div sx={{ fontSize: "90%", textAlign: "center", color: "accent" }}>
        Have an account?{" "}
        <a sx={{ variant: "components.link", color: "red" }} href="/login">
          Login
        </a>
      </div>
    </form>
  );
}

export default Login;
