/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Button, Label } from "theme-ui";
import React, { useState, memo } from "react";
import { useTable, useSortBy } from "react-table";
import { format } from "date-fns";
import axios from "axios";

import { baseUrl } from "../utils/constants";
import Modal from "../components/Modal";
import WineTable from "../components/WineTable";
import SortUp from "../icons/SortUp";
import SortDown from "../icons/SortDown";

const adminColumns = [
  {
    Header: "Date",
    accessor: "requested_for",
    Cell: (props) => {
      return (
        <div>
          {props.cell.value ? format(new Date(props.cell.value), "d MMM") : "–"}
        </div>
      );
    },
  },
  {
    Header: "User",
    accessor: "for_user.name",
  },
  {
    Header: "Email",
    accessor: "for_user.email",
  },
  {
    Header: "Type",
    accessor: "fulfilment_type",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Bottles",
    accessor: "transactions",
    Cell: (props) => {
      let bottleCount = 0;
      props.cell.value.forEach((i) => {
        bottleCount += i.quantity;
      });
      return <div>{bottleCount}</div>;
    },
  },
  {
    Header: "Note",
    accessor: "note",
    columnWidth: 300,
    Cell: (props) => {
      return (
        <div
          sx={{
            p: 2,
            fontSize: 12,
            fontFamily: "monospace",
            textAlign: "left",
          }}
        >
          {props.cell.value ? props.cell.value : "–"}
        </div>
      );
    },
  },
  {
    Header: "Requested",
    accessor: "created_at",
    Cell: (props) => {
      return <div>{format(new Date(props.cell.value), "d MMM")}</div>;
    },
  },
  {
    Header: "Approved",
    accessor: "approved_at",
    Cell: (props) => {
      return (
        <div>
          {props.cell.value
            ? format(new Date(props.cell.value), "d MMM HH:mm")
            : "–"}
        </div>
      );
    },
  },
];

const userColumns = [
  {
    Header: "Date",
    accessor: "requested_for",
    Cell: (props) => {
      return (
        <div>
          {props.cell.value
            ? format(new Date(props.cell.value), "d MMM HH:mm")
            : "–"}
        </div>
      );
    },
  },
  {
    Header: "Type",
    accessor: "fulfilment_type",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Bottles",
    accessor: "transactions",
    Cell: (props) => {
      let bottleCount = 0;
      props.cell.value.forEach((i) => {
        bottleCount += i.quantity;
      });
      return <div>{bottleCount}</div>;
    },
  },
  {
    Header: "Note",
    accessor: "note",
    columnWidth: 300,
    Cell: (props) => {
      return (
        <div
          sx={{
            p: 2,
            fontSize: 12,
            fontFamily: "monospace",
            textAlign: "left",
          }}
        >
          {props.cell.value ? props.cell.value : "–"}
        </div>
      );
    },
  },
  {
    Header: "Requested",
    accessor: "created_at",
    Cell: (props) => {
      return <div>{format(new Date(props.cell.value), "d MMM HH:mm")}</div>;
    },
  },
  {
    Header: "Approved",
    accessor: "approved_at",
    Cell: (props) => {
      return (
        <div>
          {props.cell.value
            ? format(new Date(props.cell.value), "d MMM HH:mm")
            : "–"}
        </div>
      );
    },
  },
];

export default function RequestsTable({
  data,
  isAdmin = false,
  token,
  fetchRequests,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [request, setRequest] = useState(false);

  const columns = React.useMemo(
    () => (isAdmin ? adminColumns : userColumns),
    [isAdmin]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns,
      },
      useSortBy
    );

  const closeModal = () => {
    setRequest(false);
    setModalOpen(false);
  };

  const { uuid } = request;

  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const approveRequest = () => {
    setApproveLoading(true);
    axios({
      method: "post",
      url: baseUrl + `/api/request/${uuid}/`,
      data: {
        status: "complete",
      },
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((response) => {
        setApproveLoading(false);
        if (fetchRequests) fetchRequests();
        closeModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const denyRequest = () => {
    setDenyLoading(true);
    axios({
      method: "post",
      url: baseUrl + `/api/request/${uuid}/`,
      data: {
        status: "rejected",
      },
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((response) => {
        console.log("rejected", response);
        setDenyLoading(false);
        if (fetchRequests) fetchRequests();
        closeModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteRequest = () => {
    if (
      window.confirm("Are you sure you want to delete this request?") === true
    ) {
      setDeleteLoading(true);
      axios({
        method: "delete",
        url: baseUrl + `/api/request/${uuid}/`,
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((response) => {
          setDeleteLoading(false);
          if (fetchRequests) fetchRequests();
          closeModal();
        })
        .catch((error) => {
          setDeleteLoading(false);
          console.error(error);
        });
    }
  };

  return (
    <div>
      <Modal isOpen={modalOpen} closeModal={closeModal} heading="View Request">
        {request && (
          <div>
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: 10,
                mb: 20,
              }}
            >
              <div>
                <Label sx={{ opacity: 0.2 }}>Date</Label>
                {request.requested_for
                  ? format(new Date(request.requested_for), "d MMM")
                  : "–"}
              </div>
              <div>
                <Label sx={{ opacity: 0.2 }}>Type</Label>
                {request.fulfilment_type}
              </div>
              <div>
                <Label sx={{ opacity: 0.2 }}>Status</Label>
                {request.status}
              </div>
              <div>
                <Label sx={{ opacity: 0.2 }}>Created At</Label>
                {format(new Date(request.created_at), "d MMM")}
              </div>
            </div>
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: 10,
              }}
            >
              <div>
                <Label sx={{ opacity: 0.2 }}>Customer</Label>
                {request.for_user.name} ({request.for_user.email})
              </div>
              <div>
                <Label sx={{ opacity: 0.2 }}>Address</Label>
                {request.destination ? request.destination : "–"}
              </div>
              <div>
                <Label sx={{ opacity: 0.2 }}>Note</Label>
                {request.note ? request.note : "–"}
              </div>
              <div>
                <Label sx={{ opacity: 0.2 }}>Approved At</Label>
                {request.approved_at
                  ? format(new Date(request.approved_at), "d MMM HH:mm")
                  : "–"}
              </div>
            </div>

            <div sx={{ mt: 50 }}>
              <WineTable data={request.transactions} />
            </div>

            {isAdmin && (
              <div
                sx={{
                  mt: 50,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Button
                    sx={{
                      variant: "components.button",
                      px: 40,
                      borderRadius: 100,
                      "&:hover": {
                        background: "#eb2f06",
                        color: "white",
                      },
                    }}
                    variant="ghost"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteRequest();
                    }}
                  >
                    {deleteLoading ? "Loading..." : "Delete request"}
                  </Button>
                </div>
                <div>
                  <Button
                    sx={{
                      variant: "components.button",
                      px: 40,
                      borderRadius: 100,
                    }}
                    variant="ghost"
                    onClick={(e) => {
                      e.preventDefault();
                      denyRequest();
                    }}
                  >
                    {denyLoading ? "Loading..." : "Deny request"}
                  </Button>

                  <Button
                    sx={{
                      variant: "components.button",
                      px: 40,
                      ml: 20,
                      borderRadius: 100,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      approveRequest();
                    }}
                  >
                    {approveLoading ? "Loading..." : "Approve request"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        <pre sx={{ display: "none" }}>
          <code>{JSON.stringify({ request }, null, 2)}</code>
        </pre>
      </Modal>
      <table
        {...getTableProps()}
        id="planque"
        style={{
          width: "100%",
          fontSize: 16,
          borderCollapse: "collapse",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`group-${i}`}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  sx={{
                    width: column.columnWidth
                      ? `${column.columnWidth}px`
                      : "auto",
                    textTransform: column.capitalize ? "capitalize" : "initial",
                  }}
                >
                  <div
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="table-header"
                  >
                    {column.render("Header")}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <SortDown />
                      ) : (
                        <SortUp />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} sx={{ textAlign: "center" }}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <MemoizedRow
                row={row}
                key={`row-${i}`}
                setModalOpen={setModalOpen}
                setRequest={setRequest}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const MemoizedRow = memo((props) => {
  const { row, isSelected, setModalOpen, setRequest } = props;
  return (
    <tr
      {...row.getRowProps()}
      onClick={() => {
        setModalOpen(true);
        setRequest({ ...row.original });
      }}
      sx={{
        height: 32,
        cursor: "pointer",
        backgroundColor: isSelected ? "lightGreen" : "transparent",
        "&:hover": {
          backgroundColor: isSelected ? "lightGreen" : "beige",
        },
      }}
    >
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            sx={{
              textTransform: cell.column.capitalize ? "capitalize" : "initial",
            }}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
});
