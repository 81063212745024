import React from "react";

function SortUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path stroke="#000" strokeWidth="1" d="M5 12l5-4 5 4"></path>
    </svg>
  );
}

export default SortUp;
