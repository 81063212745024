/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Heading, Button } from "theme-ui";
import Close from "../icons/Close";

export default function Modal({
  isOpen = false,
  children,
  closeModal,
  heading
}) {
  const styles = isOpen
    ? {
        opacity: 1,
        visibility: "visible",
        pointerEvents: "all"
      }
    : {
        opacity: 0,
        visibility: "hidden",
        pointerEvents: "none"
      };

  const modalStyles = isOpen
    ? {
        transform: "translateY(0)",
        opacity: 1
      }
    : {
        transform: "translateY(100px)",
        opacity: 0
      };

  return (
    <div
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 100,
        overflow: "scroll",
        py: 30,
        transition: "all 200ms ease-in-out",
        ...styles
      }}
    >
      <div
        sx={{
          padding: [20, 30, 50],
          background: "white",
          maxWidth: 1100,
          width: "90%",
          mx: "auto",
          transition: "all 200ms cubic-bezier(0.190, 1.000, 0.220, 1.000)",
          position: "relative",
          zIndex: 1,
          ...modalStyles
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 20
          }}
        >
          <Heading sx={{ fontSize: 42 }}>{heading}</Heading>
          <Button
            variant="reset"
            onClick={closeModal}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              background: "black",
              color: "white",
              fontSize: 20,
              width: 40,
              height: 40,
              "&:hover": {
                transform: "translateY(-1px)"
              }
            }}
          >
            <Close fill="white" />
          </Button>
        </div>
        {children}
      </div>
      <div
        sx={{
          position: "fixed",
          background: "rgba(0, 0, 0, 0.5)",
          top: 0,
          left: 0,
          cursor: "pointer",
          width: "100%",
          height: "100%"
        }}
        onClick={() => closeModal()}
      />
    </div>
  );
}
