/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useTable, useSortBy } from "react-table";

import React, { memo } from "react";
import SortUp from "../icons/SortUp";
import SortDown from "../icons/SortDown";

export default function WineTable({ data, uuid }) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "variant.colour",
        columnWidth: 70,
        capitalize: true,
      },
      {
        Header: "Country",
        accessor: "bottle.country",
      },
      {
        Header: "Region",
        accessor: "bottle.region",
      },
      {
        Header: "Designation",
        accessor: "bottle.appellation",
        sortType: "basic",
      },
      {
        Header: "Producer",
        accessor: "bottle.domain",
        sortType: "basic",
      },

      {
        Header: "Cuvée",
        accessor: "bottle.cuvee",
      },
      {
        Header: "Vintage",
        accessor: "variant.vintage",
        sortType: "basic",
        columnWidth: 100,
        Cell: (props) => {
          const { value } = props.cell;
          return <div>{value === "0" ? "NV" : value}</div>;
        },
      },
      {
        Header: "Size",
        accessor: "variant.size",
        sortType: "basic",
        columnWidth: 100,
        Cell: (props) => {
          return <div>{formatSize(props.cell.value)}</div>;
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        sortType: "basic",
        columnWidth: 100,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns,
      },
      useSortBy
    );

  return (
    <table
      {...getTableProps()}
      id="planque"
      style={{
        width: "100%",
        fontSize: 16,
        borderCollapse: "collapse",
      }}
    >
      <thead
        sx={{
          backgroundColor: "black",
          color: "white",
          lineHeight: "30px",
          height: 30,
          position: "sticky",
          top: 0,
        }}
      >
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                sx={{
                  width: column.columnWidth
                    ? `${column.columnWidth}px`
                    : "auto",
                  textTransform: column.capitalize ? "capitalize" : "initial",
                }}
              >
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="table-header"
                >
                  {column.render("Header")}

                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <SortDown />
                    ) : (
                      <SortUp />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} sx={{ textAlign: "center" }}>
        {rows.map((row, i) => {
          prepareRow(row);
          return <MemoizedRow row={row} />;
        })}
      </tbody>
    </table>
  );
}

const MemoizedRow = memo((props) => {
  const { row, isSelected } = props;
  return (
    <tr
      {...row.getRowProps()}
      sx={{
        minHeight: 32,
        cursor: "pointer",
        backgroundColor: isSelected ? "lightGreen" : "transparent",
        "&:hover": {
          backgroundColor: isSelected ? "lightGreen" : "beige",
        },
      }}
    >
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            sx={{
              py: 10,
              textTransform: cell.column.capitalize ? "capitalize" : "initial",
            }}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
});

function formatSize(size) {
  return `${size}ml`;
  // return `${size / 10} cl`;
}
