const theme = {
  colors: {
    text: "black",
    background: "#fff",
    pastel: "#FEFDF8",
    red: "#F05826",
    beige: "#E3E2DE",
    error: "#d63031",
    green: "#43a047",
    grey: "rgba(0, 0, 0, 0.2)",
  },
  fonts: {
    default: '"Mabry Pro", Courier, Monaco',
    heading: '"Pondre", Courier, Monaco',
  },
  fontWeights: {
    body: 300,
    heading: 700,
  },
  fontSizes: {
    default: 16,
  },
  text: {
    heading: {
      fontWeight: 300,
    },
  },
  components: {
    link: {
      textDecoration: "none",
      transition: "all 100ms ease-in-out",
      color: "black",
      "&:hover, &:active": {
        color: "red",
      },
    },
    reset: {
      backgroundColor: "transparent",
      border: "none",
      color: "black",
      padding: 0,
      cursor: "pointer",
      fontFamily: "default",
      "&:focus": {
        outline: "none",
        background: "none",
      },
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    box: {
      borderColor: "white",
      boxShadow: "0 4px 16px 0 rgba(33,37,41,0.04)",
      background: "white",
      padding: 20,
      borderRadius: 16,
      fontFamily: "'Mabry Pro', system-ui, sans-serif",
    },
  },
  breakpoints: ["40em", "56em", "64em"],
  styles: {
    root: {
      fontFamily: "default",
      fontWeight: "body",
      opacity: 1,
      fontSize: 18,
      transition: "opacity 500ms linear",
      color: "black",
      backgroundColor: "pastel",
    },
  },
  buttons: {
    primary: {
      padding: "14px",
      fontSize: 18,
      backgroundColor: "black",
      color: "white",
      borderRadius: 0,
      fontWeight: 400,
      transition: "all 50ms ease-in-out",
      fontFamily: "'Mabry Pro', system-ui, sans-serif",
      cursor: "pointer",
      "&:hover": {
        transform: "translateY(-1px)",
      },
    },
    ghost: {
      padding: "14px",
      background: "transparent",
      color: "black",
      border: "1px solid rgba(0, 0, 0, 0.2)",
      cursor: "pointer",
      "&:hover": {
        color: "white",
        background: "black",
        transform: "translateY(-1px)",
      },
    },
    reset: {
      padding: 0,
      background: "none",
      borderRadius: 0,
      cursor: "pointer",
    },
  },
  forms: {
    label: {
      fontSize: 12,
      mb: "10px",
      textTransform: "uppercase",
      letterSpacing: 1,
      fontWeight: "bold",
    },
    input: {
      padding: "14px",
      borderColor: "grey",
      fontSize: 18,
      borderRadius: 0,
      color: "text",
      background: "white",
      fontFamily: "'Mabry Pro', system-ui, sans-serif",
      "&:focus": {
        outline: "none",
        boxShadow: "0 0 0 1px #F05826",
        borderColor: "red",
      },
      "&:disabled": {
        backgroundColor: "rgb(245, 245, 245)",
      },
      "::placeholder": {
        color: "black",
        opacity: 0.5,
      },
    },
    textarea: {
      color: "text",
      borderColor: "white",
      boxShadow: "0 4px 16px 0 rgba(33,37,41,0.04)",
      background: "white",
      padding: 20,
      borderRadius: 16,
      fontFamily: "'Mabry Pro', system-ui, sans-serif",
      "&:focus": {
        outline: "none",
        // boxShadow: "0 0 0 1px #D67E60",
        // borderColor: "secondary"
      },
      "::placeholder": {
        color: "accent",
      },
    },
  },
};
export default theme;
